import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const CustomLink = styled.a`
  font-size: 20px;
  margin-top: 8px;
`;

const Description = styled.div`
  margin-top: 16px;
  font-size: 16px;
  & > a {
    font-size: 16px;
    color: darkslategray !important;
    width: fit-content;
    text-decoration: none;
    position: relative;
    &::after {
      content: "";
      bottom: -1px;
      left: 0px;
      position: absolute;
      transition: all 300ms;
      width: 100%;
      height: 1px;
      transform: scale(0); 
      background-color: transparent;
    }
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: darkslategray;
    }
  }
  @media(min-width: 1200px) {
    font-size: 16px;
    & > a {
      font-size: 16px;
    }
  }
`;

const Tag = styled.div`
  background: #2f4f4f75;
  padding: 2px 8px;
  margin-right: 8px;
  border-radius: 4px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: rgb(200, 200, 200);
  padding: 0;
  width: 100%;
  padding: 24px;
  max-height: 100%;
  @media(min-width: 1200px) {
    padding: 0;
  }
`;

const SelectedProject = ({project, width = '80vw'}) => {
  return !project ? null : (
      <SelectedContainer>
        <Img
          fluid={project.images[1].childImageSharp.fluid}
          style={{height: width, width, maxWidth: '65vh', maxHeight: '65vh'}}
        />
        <InfoContainer>
          <div style={{marginTop: 16, display: 'flex', flexWrap: 'wrap', fontSize: 16}}>
            {project.tags.map(tag => (
              <Tag key={tag}>
                {tag}
              </Tag>
            ))}
          </div>
          {project.location.venue && (
            <CustomLink href={project.location.link} target="_blank" rel="noreferrer">
              {project.location.venue}
            </CustomLink>
          )}
          <div style={{marginTop: project.location.venue ? 2 : 8, fontSize: 15}}>
            {`${project.location.city}, ${project.date}`}
          </div>
          <Description dangerouslySetInnerHTML={{__html: project.description}} />
        </InfoContainer>
      </SelectedContainer>
  )
};

export default SelectedProject;
