import React from 'react';
import styled from 'styled-components';
import {useInView} from 'react-intersection-observer';
import {CSSTransition} from 'react-transition-group';

const AboutTitleText = styled.h3`
`;

const Container = styled.div`
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: darkslategray;
  opacity: 0;
  transition: all 800ms ease-out;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-enter-done {
    opacity: 1;
  }
`


const AboutTitle = ({children, cvLink}) => {
  const {ref, inView} = useInView({
    triggerOnce: true,
    initialInView: false
  });
  return (
    <CSSTransition in={inView} timeout={800} classNames="fade">
      <Container>
        <AboutTitleText ref={ref}>{children}</AboutTitleText>
        {cvLink && <a style={{marginLeft: 'auto'}} href={cvLink} download>Download cv</a>}
      </Container>
    </CSSTransition>
  )
}

export default AboutTitle;
