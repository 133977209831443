import React from 'react'
import styled from 'styled-components';
import Slider from './Slider';
import SelectedProject from './SelectedProject';
import {playerContext} from '../services/player';

// const Tablet = ({ children }) => {
//   const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
//   return isTablet ? children : null
// }

const sliderSettings = {
  arrows: false,
  swipe: false,
  speed: 500,
  fade: true,
  vertical: false,
  draggable: false,
  slidesToShow: 1,
  easing: 'ease-out'
};

const LeftContainer = styled.div`
  display: none;
  position: relative;
  height: 100%;
  width: 40%;
  /* transform: scaleX(0); */
  /* transform-origin: left; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @media(min-width: 992px) {
    display: block;
  }
`;

const DesctopSelected = ({containerWidth, projects}) => {
  const {nowPlayingIndex, isOverlayOpen} = React.useContext(playerContext);
  const sliderRef = React.useRef();
  React.useEffect(() => {
    if (nowPlayingIndex === null) return;
    sliderRef.current && sliderRef.current.slickGoTo(nowPlayingIndex);
  }, [nowPlayingIndex])
  return (
    <LeftContainer>
      <Slider {...sliderSettings} ref={sliderRef} width={containerWidth * 0.4} style={{pointerEvents: isOverlayOpen}}>
        {projects.map(project => (
          <div key={project.title}>
            <SelectedProject
              key={project.title}
              project={project}
              width={containerWidth * 0.4}
            />
          </div>
        ))}
      </Slider>
    </LeftContainer>
  )
}

export default DesctopSelected;

