import styled from 'styled-components';
import ReactSlick from 'react-slick';

const Slider = styled(ReactSlick)`
  user-select: text;
  &.slick-slider .slick-list .slick-track .slick-slide {
    pointer-events: none;
    width: ${({width}) => width ? `${width}px !important` : '100vw'};
    height: calc(100vh - 106px);
    overflow-y: auto;
  }
  &.slick-slider .slick-list .slick-track .slick-active {
    ${({overlay}) => overlay && 'pointer-events: auto'};
  }
  @media(min-width: 992px) {
    &.slick-slider .slick-list .slick-track .slick-slide {
      height: auto;
    }
    &.slick-slider .slick-list .slick-track .slick-active {
      pointer-events: auto;
    }
  }
`;

export default Slider;
