import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay, faPause} from '@fortawesome/free-solid-svg-icons';
import {playerContext} from '../services/player';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
// import {windowContext} from '../services/window';

const PlayerContainer = styled.div`
  width: 100%;
  position: fixed;
  cursor: pointer;
  z-index: 101;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 46px;
  box-sizing: border-box;
  transition: transform 0.5s ease-out;
  transform-origin: bottom;
  background: rgba(0, 0, 0, 1);
  /* @media(min-width: 576px) {
    width: 540px;
    left: calc(50% - 270px);
  }
  @media(min-width: 768px) {
    width: 720px;
    left: calc(50% - 360px);
  } */
  @media(min-width: 992px) {
    background: rgba(0, 0, 0, 0.97);
    width: 960px;
    left: calc(50% - 480px);
  }
  @media(min-width: 1200px) {
    left: calc(50% - 570px);
    height: 56px;
    width: 1140px;
  }
  `;

const ControlsContainer = styled.div`
  border-right: 2px solid rgba(105, 105, 105, 0.25);
  border-top: 2px solid rgba(105, 105, 105, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 46px;
  @media(min-width: 576px) {
    border-left: 2px solid rgba(105, 105, 105, 0.25);
  }
  @media(min-width: 1200px) {
    width: 56px;
    height: 56px;
  }
`;

const PlayButton = styled(FontAwesomeIcon)`
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
`;

const PauseButton = styled(FontAwesomeIcon)`
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
`;

const SongTitle = styled.p`
  margin: 0;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-top: 2px solid rgba(105, 105, 105, 0.25);
  color: #d5d5d5;
  letter-spacing: 2px;
  @media(min-width: 1200px) {
    letter-spacing: 3px;
    font-size: 16px;
    padding: 0 20px;
  }
  `;

const SongRemaining = styled.p`
  margin: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-left: 2px solid rgba(105, 105, 105, 0.25);
  border-top: 2px solid rgba(105, 105, 105, 0.25);
  font-size: 13px;
  color: #d5d5d5;
  letter-spacing: 2px;
  width: 46px;
  @media(min-width: 576px) {
    border-right: 2px solid rgba(105, 105, 105, 0.25);
  }
  @media(min-width: 1200px) {
    width: 56px;
    letter-spacing: 3px;
    font-size: 15px;
  }
`;

const SongInfoContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  position: relative;
`;

const SongElapsedOverlay = styled(Slider)`
  position: absolute;
  height: 46px;
  transition: transform 0.5s ease;
  border-radius: 0;
  padding: 0;
  @media(min-width: 1200px) {
    height: 56px;
  }
`;

const getTrackTitle = (tracks, nowPlayingIndex) => tracks[nowPlayingIndex].title;

const Player = () => {
  const {
    nowPlayingIndex,
    playerRef,
    paused,
    setPaused,
    songDuration,
    setSongElapsedTime,
    songElapsedTime,
    show
  } = React.useContext(playerContext);
  const data = useStaticQuery(graphql`
    query {
      projects: allProjectsJson {
        edges {
          node {
            audio {
              file
              id
              title
              description
            }
          }
        }
      }
    }
  `)
  const tracks = data.projects.edges.map(({node: {audio}}) => ({...audio[0]}));
  const playerWidth = 400;

  return (
    <PlayerContainer
      width={playerWidth}
      style={{
        transform: `scaleY(${show ? '1' : '0'})`,
        opacity: `${show ? 1 : 0}`,
      }}>
      <ControlsContainer
        onClick={() => {
          setPaused(!paused);
          paused
            ? playerRef.current.play()
            : playerRef.current.pause();
        }}
      >
        {paused
          ? <PlayButton icon={faPlay} />
          : <PauseButton icon={faPause} />
        }
      </ControlsContainer>
      <SongInfoContainer>
        <SongTitle>
          {nowPlayingIndex !== null && getTrackTitle(tracks, nowPlayingIndex)}
        </SongTitle>
        <SongRemaining>
          {Math.max(0, songDuration - songElapsedTime)}
        </SongRemaining>
        <SongElapsedOverlay
          value={songElapsedTime}
          max={songDuration}
          style={{
            transform: `scaleY(${nowPlayingIndex !== null ? '1' : '0'})`
          }}
          handleStyle={{opacity: 0}}
          railStyle={{opacity: 0, height: '100%'}}
          trackStyle={{
            height: '100%',
            borderRadius: 0,
            backgroundColor: 'rgba(47, 79, 79, 0.5)',
            transition: 'width 1s linear'
          }}
          onChange={value => {
            setSongElapsedTime(value);
            playerRef.current.currentTime = value;
          }}
        />
      </SongInfoContainer>
    </PlayerContainer>
  );
};

export default Player;
