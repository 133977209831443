import React from 'react';
import styled from 'styled-components';
import {useInView} from 'react-intersection-observer';
import {CSSTransition} from 'react-transition-group';

const SectionTitleText = styled.h1`
  color: darkslategray;
  opacity: 0;
  font-family: 'Montserrat';
  transform: translateX(-40px);
  transition: all 800ms ease-out;
  &.fade-enter {
    opacity: 0;
    transform: translateX(-40px);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  &.fade-enter-done {
    opacity: 1;
    transform: translateX(0);
  }
  font-size: 40px;
  margin-bottom: 24px;
  @media(min-width: 576px) {
    font-size: 48px;
  }
  @media(min-width: 992px) {
    margin-bottom: 48px;
    font-size: 56px;
  }
  @media(min-width: 1300px) {
    font-size: 64px;
  }
`;

const SectionTitle = ({children, className}) => {
  const {ref, inView} = useInView({
    triggerOnce: true,
    initialInView: false
  });
  return (
    <CSSTransition in={inView} timeout={800} classNames="fade">
      <SectionTitleText ref={ref} className={className}>{children}</SectionTitleText>
    </CSSTransition>
  )
}

export default SectionTitle;
