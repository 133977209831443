import React from 'react';
import config from '../../config';
import SectionTitle from './SectionTitle';
import {CustomLink} from './Projects';
import styled from 'styled-components';

const Section = styled.section`
  min-height: unset;
`;

const S_Section = styled.section`
  background-size: cover;
  @media(min-width: 1600px) {
    background-size: contain;
  }
`;

export default function Subscribe() {
  return (
    <Section id="contact" className="projects-section" style={{flexDirection: "column"}}>
      <div className="container">
        <SectionTitle>Contact</SectionTitle>
      </div>
      <S_Section id="signup" className="signup-section" style={{width: '100%'}}>
        <div className="container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <CustomLink style={{fontSize: 24}} href={`mailto:${config.email}`}>{config.email}</CustomLink>
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                key={url}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'darkslategray',
                  textDecoration: 'none',
                  width: 40,
                  height: 40,
                  marginTop: 16,
                  borderRadius: '50%',
                  color: 'rgba(255, 255, 255, 0.5)'
                }}
              >
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div>
      </S_Section>
    </Section>
  );
}
