import React from 'react';
import {useInView} from 'react-intersection-observer';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';

const Text = styled.div`
  text-align: left;
  font-size: 16px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transform: translateY(20px);
  transition: all 800ms ease-out;
  &.fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
  & > a {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    & > a {
      font-size: 18px;
    }
  }
`

const Paragraph = ({children}) => {
  const {ref, inView} = useInView({
    triggerOnce: true
  });
  return (
    <CSSTransition in={inView} timeout={800} classNames="fade">
      <Text ref={ref}>
        {children}
      </Text>
    </CSSTransition>
  )
}

export default Paragraph;
