import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CSSTransition} from 'react-transition-group';
import SelectedProject from '../components/SelectedProject';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import Slider from '../components/Slider';
import {useStaticQuery, graphql} from 'gatsby';
import {playerContext} from '../services/player';

const CloseButton = styled(FontAwesomeIcon)`
  color: darkslategray;
  font-size: 18px;
  cursor: pointer;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
`;

const OverlayContainer = styled.div`
  display: block;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  /* height: calc(100vh + 46px);
  width: 100vw; */
  background: rgba(0, 0, 0, 0.97);
  opacity: 0;
  transition: opacity 500ms ease-out;
  &.fade-in-enter {
    opacity: 0;
    pointer-events: auto;
  }
  &.fade-in-enter-active {
    opacity: 1;
  }
  &.fade-in-enter-done {
    pointer-events: auto;
    opacity: 1;
  }
  &.fade-in-exit {
    pointer-events: none;
    opacity: 1;
  }
  &.fade-in-exit-active {
    opacity: 0;
  }
  &.fade-in-exit-done {
    pointer-events: none;
    opacity: 0;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

const Overlay = () => {
  const {isOverlayOpen, setIsOverlayOpen, setNowPlayingIndex, nowPlayingIndex, nowPlayingTitle} = React.useContext(playerContext);
  const data = useStaticQuery(graphql`
    query {
      projects: allProjectsJson {
        edges {
          node {
            title
            description
            tags
            date
            location {
              city
              venue
              link
            }
            collaborators
            images {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.projects.edges.map(({node}) => node);

  const mobileSliderSetting = {
    arrows: false,
    speed: 500,
    vertical: false,
    slidesToShow: 1,
    easing: 'ease-out',
    draggable: true,
    swipe: true,
    touchThreshold: 20,
    adaptiveHeight: true,
    beforeChange: (_, newIndex) => setNowPlayingIndex(newIndex),
  };
  const sliderRef = React.useRef();
  React.useEffect(() => {
    if (nowPlayingIndex === null) return;
    sliderRef.current && sliderRef.current.slickGoTo(nowPlayingIndex);
  }, [nowPlayingIndex])
  return (
    <CSSTransition in={isOverlayOpen} timeout={500} classNames="fade-in">
      <OverlayContainer>
        <div style={{height: 60, display: 'flex', zIndex: 100, position: 'relative', alignItems: 'center', justifyContent: 'center'}}>
          <p style={{color: 'darkslategray', margin: 0}}>{nowPlayingTitle}</p>
          <div style={{position: 'absolute', top: 0, right: 0, height: 60, width: 60, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={() => {
              setIsOverlayOpen(false);
            }}
          >
            <CloseButton icon={faTimes} />
          </div>
        </div>
        <Slider {...mobileSliderSetting} ref={sliderRef} overlay={isOverlayOpen} style={{height: 'calc(100vh)'}}>
          {projects.map(project => (
            <div key={project.title}>
              <SelectedProject
                key={project.title}
                project={project}
              />
            </div>
          ))}
        </Slider>
      </OverlayContainer>
    </CSSTransition>
  )
};

export default Overlay;
