import React from 'react';
import PlayerContext, {playerContext} from '../services/player';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import config from '../../config';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';
import Projects from '../components/Projects'
import styled from 'styled-components';
import Player from '../components/Player';
import Paragraph from '../components/Paragraph';
import SectionTitle from '../components/SectionTitle';
import AboutTitle from '../components/AboutTitle';
import SiteTitle from '../components/SiteTitle';
import Footer from '../components/Footer';
import {useStaticQuery, graphql} from 'gatsby';
import AnimateHeight from 'react-animate-height';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleUp} from '@fortawesome/free-solid-svg-icons';
import {aboutParagraphs, biographyParagraphs} from '../content/text';

const Background = styled.section`
  padding: 16px 0;
  margin: 32px 0;
  justify-content: flex-end;
  @media(min-width: 576px) {
    padding: 70px 0;
    margin: 48px 0;
  }
  @media(min-width: 992px) {
    padding: 120px 0;
    margin: 64px 0;
  }
  @media(min-width: 1300px) {
    padding: 200px 0;
    margin: 80px 0;
  }
  `

const ReadMoreLessButton = styled(FontAwesomeIcon)`
  color: darkslategrey;
  transition: transform 400ms ease;
  font-size: 24px;
  cursor: pointer;
  @media(min-width: 1200px) {
    font-size: 32px;
  }
`;

const ListenButton = () => {
  const {nowPlayingIndex, setNowPlayingIndex} = React.useContext(playerContext);
  return (
    <Scroll
    type="id"
    element="listen"
    onClick={() => nowPlayingIndex === null && setNowPlayingIndex(0)}
    >
      <a href="#listen" className="btn btn-primary">
        Listen
      </a>
    </Scroll>
  )
};

const SeeMoreLess = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  color: darkslategray;
  @media(min-width: 992px) {
    display: none;
  }
`;

const AboutContainer = styled.div`
  justify-content: flex-end;
  @media(min-width: 576px) {
    padding: 12px;
    margin: 4px auto;
  }
  @media(min-width: 992px) {
    margin: 40px auto;
    padding: 0;
  }
  @media(min-width: 1300px) {
    margin: 80px auto;
    padding: 0;
  }
`;

const HeightAnimated = styled(AnimateHeight)`
  @media(min-width: 992px) {
    display: none;
  }
`;

const Bio = styled.div`
  padding: 0;
  flex: 1;
  max-width: 100%;
  margin-top: 32px;
  display: none;
  @media(min-width: 992px) {
    display: block;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      files: allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);
  const files = data.files.edges.map(({node}) => node);
  const [showMore, setShowMore] = React.useState(false);
  const thesis = files.find(file => file.name === 'Sonology_Thesis_Orestis_Zafeiriou').publicURL;
  return (
    <Layout>
      <PlayerContext>
        <Header />
        <Player />
        <header className="masthead" style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>
          <div className="container d-flex h-100 align-items-center">
            <div className="mx-auto text-center">
              <SiteTitle heading={config.heading} subHeading={config.subHeading} />
              <ListenButton />
            </div>
          </div>
        </header>

        <Background id="about" className="about-section">
          <AboutContainer className="container">
            <SectionTitle>About</SectionTitle>
              <div className="col-lg-8 mx-auto" style={{padding: 0, flex: 1, maxWidth: '100%'}}>
                {/* <AboutTitle>My work</AboutTitle> */}
                <div className="text-center" style={{}}>
                  {aboutParagraphs.map(p => <Paragraph>{p}</Paragraph>)}
                </div>
              </div>
              <HeightAnimated
                id='example-panel'
                duration={500}
                height={(!showMore) ? 0 : 'auto'} // see props documentation below
              >
                <div className="col-lg-8 mx-auto" style={{padding: 0, flex: 1, maxWidth: '100%', marginTop: 32}}>
                  <AboutTitle>Biography</AboutTitle>
                  <div className="text-center" style={{}}>
                    {biographyParagraphs.map(p => <Paragraph>{p}</Paragraph>)}
                  </div>
                </div>
              </HeightAnimated>
              <Bio className="col-lg-8 mx-auto">
                <AboutTitle>Biography</AboutTitle>
                <div className="text-center" style={{}}>
                  {biographyParagraphs.map(p => <Paragraph>{p}</Paragraph>)}
                </div>
              </Bio>
              <SeeMoreLess onClick={() => setShowMore(!showMore)}>
                <p style={{marginBottom: 0}}>{showMore ? 'See less' : 'See more'}</p>
                <ReadMoreLessButton icon={faAngleDoubleUp} style={{transform: `rotate(${showMore ? '0deg' : '180deg'}`}}/>
              </SeeMoreLess>
          </AboutContainer>
        </Background>

        <Projects />

        <Subscribe />
        <Footer />
      </PlayerContext>
    </Layout>
  );
}
export default IndexPage;
