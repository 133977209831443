import React from 'react';
import styled from 'styled-components';
import {useInView} from 'react-intersection-observer';
import {CSSTransition} from 'react-transition-group';

const SiteTitleText = styled.h1`
  opacity: 0.5;
  font-family: 'Montserrat';
  transition: all 800ms ease-out;
  &.fade-enter {
    opacity: 0.5;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-enter-done {
    opacity: 1;
  }
`;

const SiteSubtitleText = styled.h2`
  opacity: 0.5;
  font-family: 'Montserrat';
  transition: all 800ms ease-out;
  &.fade-in-enter {
    opacity: 0.5;
  }
  &.fade-in-enter-active {
    opacity: 1;
  }
  &.fade-in-enter-done {
    opacity: 1;
  }
`;

const SiteTitle = ({heading, subHeading}) => {
  const {ref, inView} = useInView({
    triggerOnce: true,
    initialInView: false
  });
  return (
    <>
    <CSSTransition in={inView} timeout={800} classNames="fade">
      <SiteTitleText className="mx-auto my-0 text-uppercase" ref={ref}>Orestis</SiteTitleText>
    </CSSTransition>
    <CSSTransition in={inView} timeout={800} classNames="fade">
      <SiteTitleText className="mx-auto my-0 text-uppercase" ref={ref}>Zafeiriou</SiteTitleText>
    </CSSTransition>
    <CSSTransition in={inView} timeout={800} classNames="fade-in">
      <SiteSubtitleText className="text-white-50 mx-auto mt-2 mb-5">{subHeading}</SiteSubtitleText>
    </CSSTransition>
    </>
  )
}

export default SiteTitle;
