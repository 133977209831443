import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import {playerContext} from '../services/player';
import useResize from '../hooks/useResize';
import {useInView} from 'react-intersection-observer';
import SectionTitle from './SectionTitle';
import DesctopSelected from './DesctopSelected';
import Overlay from './Overlay';

const Listen = styled(SectionTitle)`
  padding: 24px;
  @media(min-width: 1200px) {
    padding: 0;
  }
`;

const ListContainer = styled.div`
  display: ${({mobile}) => mobile ? 'flex' : 'none'};
  flex-direction: column;
  flex: 1;
  @media (min-width: 992px) {
    display: ${({mobile}) => mobile ? 'none' : 'flex'};
    margin-left: 32px;
  }
  @media (min-width: 1200px) {
    margin-left: 24px;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(100, 100, 100, 0.4);
  transition: all 500ms;
  color: ${({selected}) => selected ? 'rgba(255, 255, 255, 0.75)' : 'rgba(180, 180, 180, 0.9)'};
  background: ${({selected}) => selected ? 'rgba(47, 79, 79, 0.35)' : 'transparent'};
  &:first-child {
    border-top: 1px solid rgba(100, 100, 100, 0.4);
  }
`;

const ProjectList = ({projects, onSelect, selected, mobile}) => {
  return (
    <ListContainer mobile={mobile}>
      <div style={{
        flex: 1,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8
      }}>
        {projects.map((p, i) => (
          <ListItemContainer
            key={p.title}
            onClick={() => onSelect(p, i)}
            selected={selected?.title === p.title}
          >
            {p.title}
          </ListItemContainer>
        ))}
      </div>
    </ListContainer>
  )
}



export const CustomLink = styled.a`
  font-size: 20px;
  margin-top: 8px;
`;

const ProjectsContainer = styled.div`
  padding: 0;
  height: 100%;
  @media(min-width: 992px) {
    padding: 0;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
`;

const GridContainer = styled.div`
  @media(min-width: 576px) {
    padding: 0;
    margin: 32px 0;
  }
  @media(min-width: 992px) {
    display: flex;
    margin: 40px 0;
  }
  @media(min-width: 1300px) {
    margin: 80px 0;
  }
  opacity: 0;
  transition: all 500ms ease-out;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-enter-done {
    opacity: 1;
  }
`;

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allProjectsJson {
        edges {
          node {
            title
            description
            tags
            date
            location {
              city
              venue
              link
            }
            collaborators
            images {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const {ref, inView} = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });
  const projects = data.projects.edges.map(({node}) => node);
  const resizeTrigger = useResize();
  const gridContainerRef = React.useRef();
  const {setNowPlayingIndex, nowPlayingIndex, setShow, show, isOverlayOpen, setIsOverlayOpen} = React.useContext(playerContext);
  const [containerWidth, setContainerWidth] = React.useState(0);
  React.useEffect(() => {
    setContainerWidth(gridContainerRef.current.getBoundingClientRect().width)
  }, [resizeTrigger])
  React.useEffect(() => {
    if (show) return;
    inView && setNowPlayingIndex(0);
    setShow(inView);
  }, [inView, show, setShow])
  return (
    <section id="listen" className="projects-section" ref={ref} style={{pointerEvents: isOverlayOpen && 'none'}}>
      <ProjectsContainer className="container">
        <Overlay />
        <Listen>Listen</Listen>
        <CSSTransition in={inView} classNames="fade" timeout={500}>
          <GridContainer ref={gridContainerRef}>
            <DesctopSelected containerWidth={containerWidth} projects={projects}/>
            <ProjectList
              projects={projects}
              onSelect={(_, index) => {
                setNowPlayingIndex(index);
              }}
              selected={projects[nowPlayingIndex]}
            />
            <ProjectList
              projects={projects}
              mobile={true}
              onSelect={(_, index) => {
                setIsOverlayOpen(true);
                setNowPlayingIndex(index);
              }}
              selected={projects[nowPlayingIndex]}
            />
          </GridContainer>
        </CSSTransition>
      </ProjectsContainer>
    </section>
  )
}

export default Projects;
